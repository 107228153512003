<template>
	<div>
		<v-navigation-drawer
			@update:mini-variant="StateChange"
			absolute
			:expand-on-hover="!$vuetify.breakpoint.mobile"
			mini-variant="true"
			v-model="isOpen"
		>
			<v-list>
				<v-list-item class="px-2">
					<v-list-item-avatar class="mx-0" @click="ShowIcon">
						<v-img :src="require(`@/assets/gzlogo.png`)"></v-img>
					</v-list-item-avatar>
					<v-btn icon @click="ToggleTheme()">
						<v-icon>{{ $vuetify.theme.dark ? 'mdi-weather-sunny' : 'mdi-weather-night' }}</v-icon>
					</v-btn>
				</v-list-item>
			</v-list>

			<v-divider></v-divider>

			<v-tabs vertical>
				<v-tab @click.stop="ChangeTitle" to="/customersupport">
					<div style="display: flex">
						<v-icon left class="pr-2" style="align-self: center"> mdi-face-agent </v-icon>
						<div
							style="display: flex; flex-direction: column; text-align: left; align-self: center"
						>
							<span style="align-self: center">Support</span>
						</div>
					</div>
				</v-tab>
				<v-tab @click.stop="ChangeTitle" to="/customers">
					<div style="display: flex">
						<v-icon left class="pr-2"> mdi-account-group </v-icon>
						<div
							style="display: flex; flex-direction: column; text-align: left; align-self: center"
						>
							<span style="align-self: center">Customers</span>
						</div>
					</div>
				</v-tab>
				<v-tab @click.stop="ChangeTitle" to="/accounts" :title="'test'">
					<v-icon left class="pr-2"> mdi-cash-multiple </v-icon>
					<div style="display: flex; flex-direction: column; text-align: left">
						<span>Accounts</span>
						<span style="font-size: 0.8em">(Customer)</span>
					</div>
				</v-tab>
				<v-tab
					v-if="CheckPermission(csPermission['SupplierAccounts.DataMatching.Read'])"
					@click.stop="ChangeTitle"
					:to="`${
						CheckPermission(csPermission['SupplierAccounts.DataMatching.Read'])
							? 'supplieraccounts'
							: 'customersupport'
					}`"
					clas
				>
					<v-icon left class="pr-2 pt-1"> mdi-truck </v-icon>
					<div style="display: flex; flex-direction: column; text-align: left">
						<span>Accounts</span>
						<span style="font-size: 0.8em">(Supplier)</span>
					</div>
				</v-tab>
			</v-tabs>
			<v-footer style="width: 400px; padding-left: 0">
				<v-tab target="_blank" href="https://greenzone.freshservice.com/support/tickets/new">
					<v-icon left class="pr-1"> mdi-account-question </v-icon>
					Log An Issue
				</v-tab>
			</v-footer>
		</v-navigation-drawer>
	</div>
</template>

<style lang="scss" scoped>
@import 'HomePage.scss';
</style>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { user$ } from '@gz/auth';
import 'isomorphic-fetch';

import { BaseService } from '../services/BaseService';
import { CsPermissionEnum } from 'greenzonegateway.classes/lib/classes';
import { userStore } from '@/store/userPinia';

@Component({
	components: {},
})
export default class HomePage extends Vue {
	localUser$ = user$;
	user = { name: 'User', username: '' };
	isOpen?: boolean;
	showSupplierIcon = false;
	clicks = 0;

	baseService = new BaseService();
	csPermission = CsPermissionEnum; // for use in html template
	userStore = userStore();

	port = '9000';
	tenantId = 'daf84c0a-e8bf-4a54-910c-90a081a561db';
	clientId = 'e2dec960-7dda-4496-a328-066a9b5eff38';
	clientSecret = 'fbh8Q~sv8boUKvX.f1h9.xdVP1cfe2p_dalWIckz';
	scopes = 'User.Read.All, profile, email, openid';
	redirectUri = window.location.href.includes('localhost')
		? 'http://localhost:9000/customersupport'
		: window.location.href.includes('staging')
		? 'https://greenzonegateway-staging.azurewebsites.net/customersupport'
		: 'https://greenzonegateway.azurewebsites.net/customersupport';

	authorityHost = 'https://login.microsoftonline.com/daf84c0a-e8bf-4a54-910c-90a081a561db';

	ToggleTheme() {
		// eslint-disable-next-line no-undef
		let vals: NodeListOf<Element>;

		if (this.$vuetify.theme.dark) {
			var elems = new Set([
				...document.querySelectorAll('.v-application.theme--dark'),
				...document.querySelectorAll('.layout-container .theme--dark'),
			]);

			elems.forEach((element) => {
				element.classList.remove('theme--dark');
				element.classList.add('theme--light');
			});
		} else {
			var elems2 = new Set([
				...document.querySelectorAll('.v-application.theme--light'),
				...document.querySelectorAll('.layout-container .theme--light'),
			]);

			elems2.forEach((element) => {
				element.classList.remove('theme--light');
				element.classList.add('theme--dark');
			});
		}

		this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
		localStorage.setItem('dark_theme', this.$vuetify.theme.dark.toString());
	}

	ShowIcon() {
		if (this.clicks === 4) {
			this.showSupplierIcon = true;
		} else if (this.clicks <= 4) {
			this.clicks++;
		}
	}

	GoToSupplier() {
		return ' http://' + window.location.host + '/supplieraccounts';
	}

	ChangeTitle(data: any, path: string) {
		var val = document.getElementById('root-project')?.getElementsByTagName('span')[0];

		if (data.currentTarget.innerText.toLowerCase().includes('\n')) {
			const result = data.currentTarget.innerText.toLowerCase().split('\n')[0];
			const result2 = data.currentTarget.innerText.toLowerCase().split('\n')[1];
			(val as HTMLElement).innerText = result + ' - ' + result2;
		} else {
			(val as HTMLElement).innerText = data.currentTarget.innerText.toLowerCase();
		}

		if (this.$vuetify.breakpoint.mobile) {
			this.ShowSidebar();
		}
	}
	StateChange(data: any) {
		const el = document.getElementById('root-navigation-drawer');

		if (!data) {
			(el as HTMLElement).style.width = '240px';
		} else {
			(el as HTMLElement).style.width = 'auto';
		}
	}

	ShowSidebar() {
		const element = document.getElementsByClassName('v-navigation-drawer')[0];
		const classList = element.classList;
		const exists = [...classList].find((classes) => classes.includes('v-navigation-drawer--close'));

		if (exists) {
			document
				.getElementsByClassName('v-navigation-drawer')[0]
				.classList.remove('v-navigation-drawer--close');

			document
				.getElementsByClassName('v-navigation-drawer')[0]
				.classList.remove('v-navigation-drawer--is-mobile');

			(document.getElementsByClassName('v-navigation-drawer')[0] as HTMLElement).style.transform =
				'translateX(0)';
		} else {
			document
				.getElementsByClassName('v-navigation-drawer')[0]
				.classList.add('v-navigation-drawer--close');

			document
				.getElementsByClassName('v-navigation-drawer')[0]
				.classList.add('v-navigation-drawer--is-mobile');

			(document.getElementsByClassName('v-navigation-drawer')[0] as HTMLElement).style.transform =
				'translateX(-100%)';
		}
	}

	CheckPermission(perm: CsPermissionEnum) {
		debugger;
		return this.userStore.GetUserPermissions.includes(CsPermissionEnum[perm]);
	}

	async created() {
		this.localUser$.subscribe(async (user: any) => {
			if (user) {
				this.user = user;
			}
		});
	}

	destroyed() {
		this.localUser$.unsubscribe();
	}
}
</script>
