<template>
	<v-app class="sidebar-container">
		<v-main>
			<HomePage />

			<!-- <router-view /> -->
		</v-main>
	</v-app>
</template>

<style scoped lang="scss">
.sidebar-container {
	// margin: 0 !important;
	// box-shadow: 0px 0px 5px var(--v-grey-darken2) !important;

	background: none !important;
	border: none;
	box-shadow: none !important;
}
</style>

<style>
iframe {
	display: none;
}
</style>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { userStore } from './store/userPinia';

import HomePage from './views/HomePage.vue';

@Component({
	components: {
		HomePage,
	},
})
export default class extends Vue {
	userStore = userStore();

	created() {
		this.userStore.InitialiseSubscriptions();

		const theme = localStorage.getItem('dark_theme');
		if (theme) {
			if (theme === 'true') {
				this.$vuetify.theme.dark = true;
			} else {
				this.$vuetify.theme.dark = false;
			}
		} else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
			this.$vuetify.theme.dark = true;
			localStorage.setItem('dark_theme', this.$vuetify.theme.dark.toString());
		}
	}
}
</script>
